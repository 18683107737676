<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'待办事项'"
      :rules="rules"
    >
      <div class="search" slot="tool">
        <el-input
          style="width: 280px"
          clearable
          placeholder="根据标题查询"
          v-model="searchQuery.title"
          class="searchInput"
        >
          <template slot="prepend">标题</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/office/waitHandle",
      searchQuery: { title: "" },
      rules: {
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
        handleDate: [
          {
            required: true,
            message: "请选择办理时间",
            trigger: ["blur", "change"],
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: ["blur", "change"],
          },
        ],
        // content: [{ required: true, validator:(rule,value,callback) => {
        //   if(!value){
        //     callback(new Error("请输入内容"))
        //   }else{
        //     callback()
        //   }
        // }, trigger: ["blur","change"] }],
      },
      props: [
        {
          label: "标题",
          prop: "title",
          align: "center",
        },
        {
          label: "办理时间",
          prop: "handleDate",
          align: "center",
        },

        // {
        //   label: "办理人",
        //   prop: "content",
        //   align: "center",
        // },
        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },

        {
          label: "状态",
          prop: "status",
          align: "center",
          type: "colorText",
          formatter: function (row) {
            const statusMap = {
              0: { color: "#F56C6C", text: "未处理" },
              1: { color: "#909399", text: "处理中" },
              2: { color: "#67C23A", text: "已处理" },
            };
            return statusMap[row.status];
          },
        },
      ],
      formProps: [
        {
          label: "标题",
          prop: "title",
          type: "input",
        },

        {
          label: "办理时间",
          prop: "handleDate",
          type: "date",
        },
        {
          label: "状态",
          prop: "status",
          type: "select",
          isFull: true,
          selectData: [
            { label: "未处理", value: 0 },
            { label: "处理中", value: 1 },
            { label: "已处理", value: 2 },
          ],
        },
        {
          label: "",
          prop: "content",
          type: "editor",
          isFull: true,
        },
      ],
    };
  },
  created() {
    this.getSystemUserList();
  },
  methods: {
    async getSystemUserList() {
      await api.common.list({ apiName: "/market/personnel" }).then((res) => {
        // this.formProps[2].selectData = res.data;

        console.log(res.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 400px;
}
</style>
